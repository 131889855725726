import React, { useEffect, useRef, useState } from "react";
import { Row, Col, Card, CardBody, CardTitle, CardSubtitle, Button, Modal, ModalBody } from "reactstrap"
import { Link } from 'react-router-dom';
import Utils from '../Utility';
import { post, get, put, uploadS3, download } from "../../helpers/api_helper";
import DeleteModal from './DeleteModal';
import DataTable from 'react-data-table-component';
import { toast } from 'react-toastify';
import { AvCheckbox, AvCheckboxGroup, AvField, AvForm } from "availity-reactstrap-validation";
import moment from 'moment-timezone';
import UserProfile from "../../components/UserProfile";
import { CSVLink } from 'react-csv';
import Cropper from "react-cropper";
import "cropperjs/dist/cropper.css";
import ProgressBar from "../../components/ProgressBar";
import Resizer from "react-image-file-resizer";

const Table = (props) => {
  const { user, role, currentData, from, setCurrentData, path, loading, setLoading, view } = props;
  const [columns, setColumns] = useState([]);
  const [data, setData] = useState([]);
  const [deleteModal, setDeleteModal] = useState(false);
  const [userType, setUserType] = useState(props?.userType || "Paid");
  const [currentUser, setCurrentUser] = useState(null);
  const [notiModal, setNotiModal] = useState(false);
  const [isNavigate, setIsNavigate] = useState("");
  const [navigationType, setNavigationType] = useState("");
  const [screens, setScreens] = useState([]);
  const [search, setSearch] = useState("");
  const [dateTo, setDateTo] = useState("");
  const [dateFrom, setDateFrom] = useState("");
  const [deleteType, setDeleteType] = useState(undefined);
  const [notiType, setNotiType] = useState("individual");
  const [notiData, setNotiData] = useState(undefined);
  const [profileModal, setProfileModal] = useState(false);
  const [selectedDate, setSelectedDate] = useState("");
  const [page, setPage] = useState(0);
  const [perPage, setPerPage] = useState(100);
  const [totalPage, setTotalPage] = useState(0);
  const [sort, setSort] = useState({ "trans.createdAt": -1 });
  const [selectedScreen, setSelectedScreen] = useState("");
  const [dropData, setDropData] = useState([]);
  const [searchModal, setSearchModal] = useState(false);
  const [searchApplied, setSearchApplied] = useState(false);
  const [currentDate, setCurrentDate] = useState(() => {
    const date = new Date();
    const year = date.getFullYear();
    const month = String(date.getMonth() + 1).padStart(2, '0');
    const day = String(date.getDate()).padStart(2, '0');
    return `${year}-${month}-${day}`;
  });
  const [progress, setProgress] = useState(0);
  const cropper = useRef();

  useEffect(() => {
    if (user?.token) {
      getData();
    }
  }, [user, userType, search, dateFrom, dateTo, perPage, page]);

  useEffect(() => {
    if (user?.token) {
      console.log("calling sort get data", sort);
      getData();
    }
  }, [user, sort]);

  // console.log("params on user table", props?.userType, props?.userTime);

  useEffect(() => {
    if (user?.token) {
      getScreens();
    }
  }, [user]);

  useEffect(() => {
    if (selectedScreen == "kahaniDetail") {
      get("/story/drop", { token: user?.token })
        .then(res => {
          if (res?.statusCode == 200)
            setDropData(res?.data);
        })
    }
    if (selectedScreen == "VratDetail") {
      get("/calendar/event/drop", { token: user?.token })
        .then(res => {
          if (res?.statusCode == 200)
            setDropData(res?.data);
        })
    }
  }, [selectedScreen]);

  const getData = () => {
    // console.log("calling get Data")
    if (!loading) {
      setLoading(true);
      let body = {
        type: userType,
        search,
        dateFrom,
        dateTo,
        page,
        size: perPage,
        sort,
        token: user?.token
      }
      post("/user/list", body)
        .then(json => {
          setLoading(false);
          if (json?.statusCode == 200) {
            setData(json?.data);
            setTotalPage(json?.totalPage);
            setSearchApplied(false);
          } else {
            setData([]);
            toast.error(json?.error);
          }
        })
        .catch(error => {
          setData([]);
          setLoading(false);
          console.log('error while getting project list', error);
        });
    }
  }

  const getScreens = () => {
    if (user?.token)
      get("/screen/list", { token: user?.token })
        .then(res => {
          if (res?.statusCode == 200)
            setScreens(res?.data);
        })
  }

  useEffect(() => {
    if (userType == 'Unregistered') {
      setColumns([
        {
          name: 'S.N',
          selector: row => row?._id,
          cell: (row, index) => (index + 1) + ((page * perPage)),
          maxWidth: "100px"
        },
        {
          name: <span onClick={() => {
            const newSortOrder = sort.deviceId === 1 ? -1 : 1;
            setSort({ deviceId: newSortOrder });
          }}>Device Id</span>,
          selector: row => row?.deviceId,
          sortable: true,
          sortFunction: (rows, field, direction) => {
            return rows;
          },
        },
        {
          name: <span onClick={() => {
            const newSortOrder = sort.deviceType === 1 ? -1 : 1;
            setSort({ deviceType: newSortOrder });
          }}>OS</span>,
          cell: (row) => <>
            {row?.deviceType == "android" ?
              <img src={require("../../assets/images/android.png")} style={{ width: 40, height: 40 }} />
              : null}
            {row?.deviceType == "ios" ?
              <img src={require("../../assets/images/iOS.png")} style={{ width: 40, height: 40 }} />
              : null}
            {row?.deviceType == "web" ?
              <img src={require("../../assets/images/web.png")} style={{ width: 40, height: 40 }} />
              : null}
          </>,
          selector: row => row?.deviceType,
          sortable: true,
          sortFunction: (rows, field, direction) => {
            return rows;
          },
        },
        {
          name: <span onClick={() => {
            const newSortOrder = sort.lastSeen === -1 ? 1 : -1;
            setSort({ lastSeen: newSortOrder });
          }}>Last Active</span>,
          selector: row => row?.lastSeen,
          cell: (row) => <>
            <span style={{ textTransform: 'capitalize' }}>{moment.tz(row?.lastSeen, 'Asia/Kolkata').fromNow()}</span>
          </>,
          sortable: true,
          sortFunction: (rows, field, direction) => {
            return rows;
          },
        },
        {
          name: <span onClick={() => {
            const newSortOrder = sort.createdAt === -1 ? 1 : -1;
            setSort({ createdAt: newSortOrder });
          }}>Registered On</span>,
          selector: row => row?.createdAt,
          cell: (row) => <>
            <span style={{ textTransform: 'capitalize' }}>{moment.tz(row?.createdAt, 'Asia/Kolkata').format("DD MMM YYYY, hh:mm a")}</span>
          </>,
          sortable: true,
          sortFunction: (rows, field, direction) => {
            return rows;
          },
        },
        {
          cell: (row) => <>
            <>
              <Button
                title={"Analytic"}
                style={{ backgroundColor: Utils.themeColor, marginRight: 10, padding: 5, paddingLeft: 8, paddingRight: 8, border: 'none' }}><Link target="_blank" to={row?.deviceId ? "/anayltic/user/" + row?.deviceId : ""}><span className={"mdi mdi-finance"} style={{ fontSize: 20, color: 'white' }}></span></Link></Button>
              {row?.deviceToken &&
                <Button onClick={() => handleSendNoti(row)}
                  title={"Send Notification"}
                  style={{ backgroundColor: Utils.themeColor, marginRight: 10, padding: 5, paddingLeft: 8, paddingRight: 8, border: 'none' }}><span className={row?.isLastNoti == false ? "mdi mdi-bell-off" : "mdi mdi-bell"} style={{ fontSize: 20, color: 'white' }}></span></Button>
              }
              <Button onClick={() => handleDeleteProj(row)}
                title={"Delete"}
                style={{ backgroundColor: row?.isActive ? 'red' : Utils.themeColor, marginRight: 10, padding: 5, paddingLeft: 8, paddingRight: 8, border: 'none' }}><span className={row?.isActive ? "mdi mdi-account-alert" : "mdi mdi-cancel"} style={{ fontSize: 20 }}></span></Button>
            </>
          </>,
          name: <span onClick={() => {
            const newSortOrder = sort.isLastNoti === -1 ? 1 : -1;
            setSort({ isLastNoti: newSortOrder });
          }}>Action</span>,
          selector: row => row?.isLastNoti,
          sortable: true,
          sortFunction: (rows, field, direction) => {
            return rows;
          },
        }
      ]);
    } else {
      setColumns([
        {
          name: 'S.N',
          selector: row => row?._id,
          cell: (row, index) => (index + 1) + ((page * perPage)),
          maxWidth: "100px"
        },
        {
          name: <span onClick={() => {
            const newSortOrder = sort.name === 1 ? -1 : 1;
            setSort({ name: newSortOrder });
          }}>Name</span>,
          selector: row => row?.name,
          cell: (row, index) => <div>
            <i className={row?.deviceType == 'android' ? "mdi mdi-android" : "mdi mdi-apple-ios"} style={{ fontSize: 18 }} />
            <span style={{ textTransform: 'capitalize', marginLeft: 5 }}>{row?.name}</span>
          </div>,
          sortable: true,
          sortFunction: (rows, field, direction) => {
            return rows;
          },
        },
        {
          name: <span onClick={() => {
            const newSortOrder = sort.mobile === 1 ? -1 : 1;
            setSort({ mobile: newSortOrder });
          }}>Mobile</span>,
          selector: row => row?.mobile,
          sortable: true,
          sortFunction: (rows, field, direction) => {
            return rows;
          },
          maxWidth: '110px'
        },
        {
          name: <span onClick={() => {
            const newSortOrder = sort.lastSeen === -1 ? 1 : -1;
            setSort({ lastSeen: newSortOrder });
          }}>Last Active</span>,
          selector: row => row?.lastSeen,
          cell: (row) => <>
            <span style={{}}>{moment.tz(row?.lastSeen, 'Asia/Kolkata').fromNow()}</span>
            {/* <span style={{}}>{moment(row?.lastSeen).format("DD MMM YYYY, hh:mm A")}</span> */}
          </>,
          sortable: true,
          sortFunction: (rows, field, direction) => {
            return rows;
          },
          maxWidth: '135px'
        },
        {
          name: <span onClick={() => {
            const newSortOrder = sort.createdAt === -1 ? 1 : -1;
            setSort({ createdAt: newSortOrder });
          }}>Registered On</span>,
          selector: row => row?.createdAt,
          cell: (row) => <>
            <span style={{ textTransform: 'capitalize' }}>{moment.tz(row?.createdAt, 'Asia/Kolkata').format("DD MMM YYYY, hh:mm A")}</span>
          </>,
          sortable: true,
          sortFunction: (rows, field, direction) => {
            return rows;
          },
          maxWidth: '180px'
        },
        {
          name: <span onClick={() => {
            const newSortOrder = sort["trans.createdAt"] === -1 ? 1 : -1;
            setSort({ "trans.createdAt": newSortOrder });
          }}>Subscribed On</span>,
          selector: row => row?.trans ? row?.trans?.createdAt : "-",
          cell: (row) => <>
            <span style={{ textTransform: 'capitalize' }}>{row?.trans ? moment.tz(row?.trans?.createdAt, 'Asia/Kolkata').format("DD MMM YYYY, hh:mm A") : "-"}</span>
          </>,
          sortable: true,
          sortFunction: (rows, field, direction) => {
            return rows;
          },
          maxWidth: '180px'
        },
        {
          name: <span onClick={() => {
            const newSortOrder = sort["trans.status"] === -1 ? 1 : -1;
            setSort({ "trans.status": newSortOrder });
          }}>₹ Status</span>,
          selector: row => row?.trans ? row?.trans?.status : "-",
          cell: (row) => <>
            {row?.trans ?
              <>
                {row?.trans?.status == "Success" ?
                  <Button
                    title={"Sucess"}
                    style={{ backgroundColor: 'green', marginRight: 10, padding: 5, paddingLeft: 8, paddingRight: 8, border: 'none' }}><span className={"mdi mdi-rupee-sign"} style={{ color: 'white' }}>Success</span></Button>
                  : null}
                {row?.trans?.status == "Pending" ?
                  <Button
                    title={"Pending"}
                    style={{ backgroundColor: 'yellow', marginRight: 10, padding: 5, paddingLeft: 8, paddingRight: 8, border: 'none' }}><span className={"mdi mdi-rupee-sign"} style={{ color: 'black' }}>Pending</span></Button>
                  : null}
                {row?.trans?.status == "Cancelled" ?
                  <Button
                    title={"Cancelled"}
                    style={{ backgroundColor: 'red', marginRight: 10, padding: 5, paddingLeft: 8, paddingRight: 8, border: 'none' }}><span className={"mdi mdi-rupee-sign"} style={{ color: 'white' }}>Cancelled</span></Button>
                  : null}
                {!row?.trans?.status ?
                  <Button
                    title={"Cancelled"}
                    style={{ backgroundColor: 'red', marginRight: 10, padding: 5, paddingLeft: 8, paddingRight: 8, border: 'none' }}><span className={"mdi mdi-rupee-sign"} style={{ color: 'white' }}>Cancelled</span></Button>
                  : null}
              </>
              :
              <span style={{ textTransform: 'capitalize' }}>-</span>
            }
          </>,
          sortable: true,
          sortFunction: (rows, field, direction) => {
            return rows;
          },
          maxWidth: '125px'
        },
        {
          cell: (row) => <>
            <>
              <Button
                title={"Analytic"}
                style={{ backgroundColor: Utils.themeColor, marginRight: 10, padding: 5, paddingLeft: 8, paddingRight: 8, border: 'none' }}><Link target="_blank" to={row?._id ? "/anayltic/user/" + row?._id : ""}><span className={"mdi mdi-finance"} style={{ fontSize: 20, color: 'white' }}></span></Link></Button>
              <Button
                onClick={() => { setCurrentData(row); setProfileModal(true) }}
                title={"View"}
                style={{ backgroundColor: Utils.themeColor, marginRight: 10, padding: 5, paddingLeft: 8, paddingRight: 8, border: 'none' }}><span className={"mdi mdi-eye"} style={{ fontSize: 20, color: 'white' }}></span></Button>
              {row?.deviceToken &&
                <Button onClick={() => handleSendNoti(row)}
                  title={"Send Notification"}
                  style={{ backgroundColor: Utils.themeColor, marginRight: 10, padding: 5, paddingLeft: 8, paddingRight: 8, border: 'none' }}><span className={row?.isLastNoti == false ? "mdi mdi-bell-off" : "mdi mdi-bell"} style={{ fontSize: 20, color: 'white' }}></span></Button>
              }
              <Button onClick={() => handleDeleteProj(row)}
                title={!row?.isActive ? "Allow" : "Ban"}
                style={{ backgroundColor: !row?.isActive ? 'red' : Utils.themeColor, marginRight: 10, padding: 5, paddingLeft: 8, paddingRight: 8, border: 'none' }}><span className={!row?.isActive ? "mdi mdi-account-alert" : "mdi mdi-cancel"} style={{ fontSize: 20 }}></span></Button>
            </>
          </>,
          name: 'Action',
          ignoreRowClick: true,
          maxWidth: '190px'
        }
      ]);
    }
  }, [page, perPage, data, userType]);

  const handleUpdateProj = (proj) => {
    console.log('updating proj', proj);
    props?.setCurrentData(proj);
    props.add(true);
  }

  const handleUpdateStatus = (item) => {
    console.log('updating proj', item);
    setCurrentData({ ...item, actionType: 'Status' });
    setDeleteModal(true);
  }

  const handleDeleteProj = (proj) => {
    console.log('updating proj', proj);
    props?.setCurrentData({ ...proj, actionType: 'Delete' });
    setDeleteModal(true);
  }

  const handleSendNoti = (proj) => {
    // console.log('sending noti to ', proj);
    setCurrentUser(proj);
    setNotiModal(true);
  }

  const onDelete = () => {
    console.log('deleted', currentData);
    let body = {
      userId: currentData?._id,
      isActive: !currentData?.isActive,
      token: user?.token
    }
    let url = "/user/delete";
    post(url, body)
      .then(json => {
        console.log('response from on click action', json);
        if (json.statusCode == 200) {
          toast.success(json?.message);
          getData(0);
          setDeleteModal(false);
          props?.setCurrentData(null);
        } else {
          toast.error(json?.error);
        }
      })
      .catch(error => {
        console.error('error while deleting user', error);
      })
  }

  const handleValidSubmit = async (e, v) => {
    let body = {
      ...v,
      deviceToken: [currentUser?.deviceToken],
      token: user?.token
    }
    if (notiData?.image?.substring(0, 4) == "blob") {
      const fileData = cropper?.current?.cropper?.getCroppedCanvas().toDataURL();
      const obj = await urltoFile(fileData, new Date().getTime() + '.png', 'image/png');
      const temp = await resizeFile(obj);
      const imageRes = await uploadS3("notification", temp, "webp", onProgress);
      body.image = imageRes?.data;
    }
    setProgress(0);
    setLoading(true);
    if (searchApplied) {
      let fcms = [];
      data?.forEach(item => {
        if (item?.deviceToken) {
          fcms.push(item?.deviceToken);
        }
      });
      body.deviceToken = fcms;
    }
    console.log('handling send notification to user!', body);
    let url = "/notification/send_to_user";
    if (notiType == "uninstall")
      url = "/notification/send_to_unregistered"
    post(url, body)
      .then(res => {
        setLoading(false);
        if (res?.statusCode == 200) {
          toast.success(res?.message);
          setIsNavigate("");
          setNavigationType("");
          setCurrentUser(null);
          setNotiModal(false);
          setNotiData(undefined);
          setNotiType("individual");
          getData(0);
        } else
          toast.error(res?.error);
      })
      .catch(err => {
        console.log('error while adding money to user account!', err);
        setLoading(false);
      })
  }

  const handleRemoveUninstalled = () => {
    console.log('removing uninstalled user');
    setLoading(true);
    get("/user/remove_uninstall", { token: user?.token })
      .then(res => {
        setLoading(false);
        if (res?.statusCode == 200) {
          toast.success(res?.message);
          getData();
          setDeleteModal(false);
          setDeleteType(undefined);
        } else {
          toast.error(res?.error);
        }
      })
      .catch(err => {
        setLoading(false);
        toast.error("Something went wrong! " + err);
      })
  }

  const handleSendNotiToUnregistered = () => {
    // send_to_unregistered
    setNotiType("uninstall");
    setIsNavigate("Yes");
    setNavigationType("internalPage")
    setNotiData({ title: "प्रिय उपयोगकर्ता,", body: "हमें खेद है कि आपने अब तक मेहंदीपुर बालाजी ऐप के सभी सुविधाओं का उपयोग नहीं किया है। हम आपको ऐप के मज़ेदार और उपयोगी फ़ीचर्स का आनंद लेने की सलाह देते हैं। अभी लॉगिन करने के लिए टैप करे। ", screenName: "login" });
    setNotiModal(true);
  }

  const handlePerRowsChange = (newPerPage, page) => {
    console.log('pagi', newPerPage, page);
    setPerPage(newPerPage);
  }

  const handlePageChange = (page) => {
    console.log('paginat', page);
    setPage(page - 1);
  }

  const handleValidFilter = (e, v) => {
    console.log("values on filter", v);
    if (!loading) {
      setLoading(true);
      let body = {
        type: userType,
        search,
        dateFrom: v?.dateFrom,
        dateTo: v?.dateTo,
        page: 0,
        size: perPage,
        sort,
        payStatus: v?.payStatus,
        token: user?.token
      }
      post("/user/list", body)
        .then(json => {
          setLoading(false);
          // console.log('response from get project list', json);
          if (json?.statusCode == 200) {
            setData(json?.data);
            setTotalPage(json?.totalPage);
            setSearchModal(false);
            setSearchApplied(true);
          } else {
            setData([]);
            toast.error(json?.error);
          }
        })
        .catch(error => {
          setData([]);
          setLoading(false);
          console.log('error while getting project list', error);
        });
    }
  }

  const urltoFile = (url, filename, mimeType) => {
    return (fetch(url)
      .then(function (res) { return res.arrayBuffer(); })
      .then(function (buf) { return new File([buf], filename, { type: mimeType }); })
    );
  }

  const resizeFile = (file) =>
    new Promise((resolve) => {
      Resizer.imageFileResizer(
        file,
        1800,
        600,
        "webp",
        100,
        0,
        (uri) => {
          resolve(uri);
        },
        "file"
      );
    });

  const onProgress = (p) => {
    setProgress(p);
  }

  const handleDownloadCsv = () => {
    if (!loading) {
      setLoading(true);
      let body = {
        type: userType,
        search,
        dateFrom,
        dateTo,
        sort,
        token: user?.token
      }
      download("/user/download", body)
        .then(blob => {
          setLoading(false);
          const url = window.URL.createObjectURL(blob);
          const a = document.createElement('a');
          a.style.display = 'none';
          a.href = url;
          a.download = 'users.csv';
          document.body.appendChild(a);
          a.click();
          window.URL.revokeObjectURL(url);
        })
        .catch(error => {
          setLoading(false);
          console.log('error while getting project list', error);
        });
    }
  }

  // console.log("sort", sort);

  return (
    <React.Fragment>
      <div className="">
        <Row>
          <DeleteModal
            show={deleteModal}
            onDeleteClick={() => deleteType == "Uninstalled" ? handleRemoveUninstalled() : onDelete()}
            onCloseClick={() => setDeleteModal(false)}
            data={currentData}
            deleteType={deleteType}
          />
          <ProgressBar
            visible={progress < 1 ? false : true}
            progress={progress}
          />
          {profileModal &&
            <UserProfile
              show={profileModal}
              onCloseClick={() => setProfileModal(false)}
              userId={currentData?._id}
            />
          }
          <Modal className="modal_form" isOpen={notiModal} toggle={() => { setNotiModal(false); setCurrentUser(null) }} centered={true}>
            <ModalBody className="py-3 px-5">
              <h5 className="text-black font-size-20">Send Instant Notification to {searchApplied ? "Filtered Users" : currentUser?.name}</h5>
              <div className="p-2">
                <AvForm
                  className="form-horizontal"
                  onValidSubmit={(e, v) => {
                    handleValidSubmit(e, v)
                  }}
                >
                  <Row>
                    <Col lg={6} md={6}>
                      <div className="form-group mb-4">
                        <AvField
                          name="isNavigate"
                          label={"Navigation"}
                          value={isNavigate}
                          className="form-control"
                          type="select"
                          required
                          onChange={e => setIsNavigate(e.target.value)}
                        >
                          <option value="">Select Navigation</option>
                          <option value="Yes">Yes</option>
                          <option value="No">No</option>
                        </AvField>
                      </div>
                    </Col>
                    {isNavigate == 'Yes' &&
                      <Col lg={6} md={6}>
                        <div className="form-group mb-4">
                          <AvField
                            name="navigationType"
                            label={"Select Navigation Type"}
                            value={navigationType}
                            className="form-control"
                            type="select"
                            onChange={(e) => setNavigationType(e.target.value)}
                            required
                          >
                            <option value="">Select Navigation Type</option>
                            <option value="internalPage">Internal App Screen</option>
                            <option value="inApp">In App Link</option>
                            <option value="outApp">External Web Link</option>
                          </AvField>
                        </div>
                      </Col>
                    }
                    {isNavigate == 'Yes' &&
                      <>
                        {navigationType == 'internalPage' ?
                          <>
                            <Col lg={6} md={6}>
                              <div className="form-group mb-4">
                                <AvField
                                  name="screenName"
                                  label={"Select Navigation Type"}
                                  value={notiData?.screenName}
                                  className="form-control"
                                  type="select"
                                  required
                                  onChange={(e) => setSelectedScreen(e.target.value)}
                                >
                                  <option value="">Select App Screen</option>
                                  {screens?.map((item) => (
                                    <option value={item?.for}>{item?.title}</option>
                                  ))}
                                </AvField>
                              </div>
                            </Col>
                            {selectedScreen == "kahaniDetail" &&
                              <Col lg={6} md={6}>
                                <div className="form-group mb-4">
                                  <AvField
                                    name="link"
                                    label={`Select Story`}
                                    value={data?.link}
                                    className="form-control"
                                    type="select"
                                    required
                                  >
                                    <option value="">Select Story</option>
                                    {dropData?.map((item) => (
                                      <option value={item?._id}>{item?.titleEn}</option>
                                    ))}
                                  </AvField>
                                </div>
                              </Col>
                            }
                            {selectedScreen == "VratDetail" &&
                              <Col lg={6} md={6}>
                                <div className="form-group mb-4">
                                  <AvField
                                    name="link"
                                    label={`Select Event`}
                                    value={data?.link}
                                    className="form-control"
                                    type="select"
                                    required
                                  >
                                    <option value="">Select Event</option>
                                    {dropData?.map((item) => (
                                      <option value={item?._id}>{item?.title} ({moment.tz(item?.date, 'Asia/Kolkata').format("DD-MMM-YYYY")})</option>
                                    ))}
                                  </AvField>
                                </div>
                              </Col>
                            }
                          </>
                          :
                          <Col lg={12} md={12}>
                            <div className="form-group mb-4">
                              <AvField
                                name="link"
                                label={"Web URL"}
                                value={notiData?.link}
                                className="form-control"
                                placeholder={`Enter Url you need to open`}
                                type="url"
                                required
                              />
                            </div>
                          </Col>
                        }
                      </>
                    }
                    <Col md={12} className="mb-3 mt-2">
                      {notiData?.image ?
                        <>
                          {notiData?.image?.substring(0, 4) == "blob" ?
                            <Cropper
                              style={{ height: 'auto', width: 600 }}
                              aspectRatio={512 / 256}
                              preview=".img-preview"
                              guides={true}
                              src={notiData?.image}
                              ref={cropper}
                            />
                            :
                            <div className="img-banner">
                              <img src={notiData?.image} />
                              <div className="cross-btn" onClick={() => setNotiData({ ...notiData, image: null })}>
                                <i className="mdi mdi-close" />
                              </div>
                            </div>
                          }
                        </>
                        :
                        <AvField
                          name="image"
                          label="Notification Image"
                          className="form-control"
                          type="file"
                          accept="image/*"
                          onChange={(e) => setNotiData({ ...notiData, image: URL.createObjectURL(e.target.files[0]) })}
                        />
                      }
                    </Col>
                    <Col lg={12} md={12}>
                      <div className="mb-3 mt-2">
                        <AvField
                          name="title"
                          label="Notification Title"
                          className="form-control"
                          placeholder="Enter something about your notification"
                          type="text"
                          required
                          value={notiData?.title}
                        />
                      </div>
                    </Col>
                    <Col lg={12} md={12}>
                      <div className="mb-3 mt-2">
                        <AvField
                          name="body"
                          label="Notification Description"
                          className="form-control"
                          placeholder="Enter detailed notification"
                          type="textarea"
                          rows={4}
                          required
                          value={notiData?.body}
                        />
                      </div>
                    </Col>
                    <Col lg={12} md={12}>
                      <div className="mt-4">
                        <Row>
                          <Col md={6}>
                            <button
                              className="btn btn-primary w-100 waves-effect waves-light"
                              style={{ backgroundColor: Utils.themeColor, border: 'none' }}
                              onClick={() => { setNotiModal(false); setCurrentUser(null) }}
                              type="reset"
                            >
                              Cancel
                            </button>
                          </Col>
                          <Col md={6}>
                            <button
                              className="btn btn-primary w-100 waves-effect waves-light"
                              type="submit"
                              style={{ backgroundColor: Utils.themeColor, border: 'none' }}
                            >
                              Send
                            </button>
                          </Col>
                        </Row>
                      </div>
                    </Col>
                  </Row>
                </AvForm>
              </div>
            </ModalBody>
          </Modal>

          <Modal className="modal_form" isOpen={searchModal} toggle={() => { setSearchModal(false); setCurrentUser(null) }} centered={true}>
            <ModalBody className="py-3 px-5">
              <h5 className="text-black font-size-20">Advance Search</h5>
              <div className="p-2">
                <AvForm
                  className="form-horizontal"
                  onValidSubmit={(e, v) => {
                    handleValidFilter(e, v)
                  }}
                >
                  <Row>
                    <Col md={12}>
                      <div className="form-group mb-4">
                        <AvCheckboxGroup inline name="payStatus" label="Payment Status" required>
                          <AvCheckbox label="Success" value="Success" />
                          <AvCheckbox label="Pending" value="Pending" />
                          <AvCheckbox label="Cancelled" value="Cancelled" />
                          <AvCheckbox label="Failed" value="Failed" />
                        </AvCheckboxGroup>
                      </div>
                    </Col>
                    <Col lg={12} md={12}>
                      <div className="mb-3 mt-2">
                        <AvField
                          name="dateFrom"
                          label="Date From"
                          className="form-control"
                          // placeholder="Enter something about your notification"
                          type="date"
                          required
                          validate={{ max: { value: currentDate } }}
                        />
                      </div>
                    </Col>
                    <Col lg={12} md={12}>
                      <div className="mb-3 mt-2">
                        <AvField
                          name="dateTo"
                          label="Date To"
                          className="form-control"
                          // placeholder="Enter detailed notification"
                          type="date"
                          required
                          validate={{ max: { value: currentDate } }}
                        />
                      </div>
                    </Col>
                    <Col lg={12} md={12}>
                      <div className="mt-4">
                        <Row>
                          <Col md={6}>
                            <button
                              className="btn btn-primary w-100 waves-effect waves-light"
                              style={{ backgroundColor: Utils.themeColor, border: 'none' }}
                              onClick={() => { setSearchModal(false); setCurrentUser(null) }}
                              type="reset"
                            >
                              Cancel
                            </button>
                          </Col>
                          <Col md={6}>
                            <button
                              className="btn btn-primary w-100 waves-effect waves-light"
                              type="submit"
                              style={{ backgroundColor: Utils.themeColor, border: 'none' }}
                            >
                              Apply
                            </button>
                          </Col>
                        </Row>
                      </div>
                    </Col>
                  </Row>
                </AvForm>
              </div>
            </ModalBody>
          </Modal>

          <Col className="col-12 usertable">
            <Card>
              <CardBody>
                <Row>
                  <Col md={10}>
                    <CardTitle className="h4">{props.role}</CardTitle>
                    <CardSubtitle className="mb-3">
                      {role} those who registered himself, All the <code>Users</code> will also be able see and <code>actions</code> on the <code>Properties.</code>
                    </CardSubtitle>
                  </Col>
                  <Col md={2}>
                    <h5>
                      <Button style={{ backgroundColor: Utils.themeColor, marginLeft: 5, padding: 5, paddingLeft: 8, paddingRight: 8, border: 'none' }}
                        onClick={handleDownloadCsv}
                      >
                        Export User ({totalPage})
                      </Button>
                      <Button style={{ marginLeft: 10, backgroundColor: Utils.themeColor, marginLeft: 5, padding: 5, paddingLeft: 8, paddingRight: 8, border: 'none', borderRadius: 50 }}
                        onClick={() => setSearchModal(true)}
                      >
                        <i className="mdi mdi-filter" style={{}} />
                      </Button>
                      {searchApplied ?
                        <Button style={{ marginLeft: 10, backgroundColor: Utils.themeColor, marginLeft: 5, padding: 5, paddingLeft: 8, paddingRight: 8, border: 'none' }}
                          onClick={() => { setNotiType("individual"); setNotiModal(true) }}
                        >
                          Notify All
                        </Button>
                        : null}
                    </h5>
                  </Col>
                </Row>
                <Row className="my-3">
                  {userType != 'Unregistered' &&
                    <Col md={6}>
                      <AvForm>
                        <div>
                          <AvField
                            name="search"
                            label="Search"
                            placeholder="Search by name, email, mobile"
                            type="text"
                            value={search}
                            onChange={(e) => setSearch(e.target.value)}
                          >
                          </AvField>
                        </div>
                      </AvForm>
                    </Col>
                  }
                  {userType == 'Unregistered' &&
                    <Col md={6}>
                      <div style={{ marginTop: 20 }}>
                        <Button style={{ backgroundColor: Utils.themeColor, border: 'none' }}
                          onClick={handleSendNotiToUnregistered}
                        >
                          Send Login Notification
                        </Button>
                        <Button style={{ backgroundColor: Utils.themeColor, border: 'none', marginLeft: 10 }}
                          onClick={() => { setDeleteType("Uninstalled"); setDeleteModal(true) }}
                        >
                          Remove Uninstalled Users
                        </Button>
                      </div>
                    </Col>
                  }
                  <Col md={2}>
                    <AvForm>
                      <AvField
                        name="dateFrom"
                        label="Date From"
                        type="date"
                        value={dateFrom}
                        onChange={(e) => setDateFrom(e.target.value)}
                        validate={{ max: { value: new Date() } }}
                      >
                      </AvField>
                    </AvForm>
                  </Col>
                  <Col md={2}>
                    <AvForm>
                      <AvField
                        name="dateTo"
                        label="DateTo "
                        placeholder="Search by name, email, mobile"
                        type="date"
                        value={selectedDate}
                        onChange={(e) => setDateTo(e.target.value)}
                        validate={{ max: { value: new Date() } }}
                      >
                      </AvField>
                    </AvForm>
                  </Col>
                  <Col md={2}>
                    <div>
                      <AvForm>
                        <AvField
                          name="userType"
                          label="User Type"
                          type="select"
                          value={userType}
                          onChange={(e) => { setUserType(e.target.value); setSort({ createdAt: -1 }) }}
                        >
                          <option value="">Select Filter</option>
                          <option value="Paid">Paid</option>
                          <option value="Registered">Registered</option>
                          <option value="Unregistered">Unregistered</option>
                          <option value="Blocked">Blocked</option>
                        </AvField>
                      </AvForm>
                    </div>
                  </Col>
                </Row>
                <DataTable
                  columns={columns}
                  data={data}
                  pagination
                  paginationPerPage={100}
                  paginationRowsPerPageOptions={[100, 200, 300, 400, 500]}
                  paginationServer
                  paginationTotalRows={totalPage}
                  onChangeRowsPerPage={handlePerRowsChange}
                  onChangePage={handlePageChange}
                  customStyles={{
                    headCells: {
                      style: {
                        color: 'black',
                        fontWeight: 'bold',
                        fontSize: 15,
                        maxWidth: 80
                      },
                    },
                    cells: {
                      style: {
                        maxWidth: 50
                      }
                    }
                  }}
                />
              </CardBody>
            </Card>
          </Col>
        </Row>
      </div>
    </React.Fragment >
  )
}
export default Table;
