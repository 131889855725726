import React, { useState, useEffect, useRef } from "react";
import {
  Card,
  CardBody,
  Col,
  Row,
  CardTitle,
  Button,
  Modal,
  ModalBody
} from "reactstrap";

import { AvForm, AvField } from "availity-reactstrap-validation";
import { post, put, get, upload, uploadS3 } from '../../helpers/api_helper';
import Utils from '../Utility';
import { toast } from "react-toastify";
import Cropper from "react-cropper";
import "cropperjs/dist/cropper.css";
import moment from 'moment-timezone';
import ProgressBar from "../../components/ProgressBar";
import Resizer from "react-image-file-resizer";

const AddUserForm = (props) => {
  const { user, data, loading, setLoading } = props;
  const [target, setTarget] = useState(data?.role ? data?.role : "");
  const [descLength, setDescLength] = useState(0);
  const [projects, setProjects] = useState([]);
  const [isNavigate, setIsNavigate] = useState("");
  const [navigationType, setNavigationType] = useState("");
  const [type, setType] = useState("");
  const [file, setFile] = useState(null);
  const [imageToCrop, setImageToCrop] = useState(null);
  const imageInput = useRef();
  const cropper = useRef();
  const [isCroping, setIsCroping] = useState(false);
  const [screens, setScreens] = useState([]);
  const [selectedScreen, setSelectedScreen] = useState("");
  const [dropData, setDropData] = useState([]);
  const [progress, setProgress] = useState(0);

  useEffect(() => {
    get("/screen/list", { token: user?.token })
      .then(res => {
        if (res?.statusCode == 200)
          setScreens(res?.data);
      })
  }, []);

  useEffect(() => {
    if (data) {
      if (data?.isNavigate)
        setIsNavigate(data?.isNavigate);
      if (data?.type)
        setType(data?.type)
      if (data?.target)
        setTarget(data?.target)
      if (data?.navigationType)
        setNavigationType(data?.navigationType);
      if (data?.image)
        setFile(data?.image)
      if (data?.screenName)
        setSelectedScreen(data?.screenName)
    }
  }, [data]);

  useEffect(() => {
    if (selectedScreen == "kahaniDetail") {
      get("/story/drop", { token: user?.token })
        .then(res => {
          if (res?.statusCode == 200)
            setDropData(res?.data);
        })
    }
    if (selectedScreen == "VratDetail") {
      get("/calendar/event/drop", { token: user?.token })
        .then(res => {
          if (res?.statusCode == 200)
            setDropData(res?.data);
        })
    }
  }, [selectedScreen])

  async function handleValidSubmit(event, values) {
    console.log('values from form', values, data);
    let body = {
      ...values,
      token: user?.token
    };
    if (values?.type == "Schedule")
      body.triggerOn = moment.tz(values.date, 'Asia/Kolkata').utc().format();
    if (user) {
      if (file && file?.substring(0, 4) == "data") {
        const obj = await urltoFile(file, new Date().getTime() + '.png', 'image/png');
        console.log("file data while uploading", obj);
        const temp = await resizeFile(obj);
        const imageRes = await uploadS3("notification", temp, "webp", onProgress);
        body.image = imageRes?.data;
      }
      setProgress(0);
      setLoading(true);
      if (data?._id) {
        body.notiId = data?._id;
        let url = "/notification/update";
        put(url, body)
          .then((json) => {
            console.log('response from updating noti', json);
            setLoading(false);
            if (json.statusCode == 200) {
              toast.success(json?.message);
              props.close(false);
            } else {
              toast.error(json?.error);
            }
          })
          .catch(error => {
            console.error('error while adding user', error);
            toast.error('Something Went Wrong!');
            setLoading(false);
          })
      } else {
        body.addedBy = user?._id;
        post("/notification", body)
          .then((json) => {
            console.log('response from adding user', json);
            setLoading(false);
            if (json.statusCode == 200) {
              toast.success(json?.message);
              props.close(false);
            } else {
              toast.error(json?.error);
            }
          })
          .catch(error => {
            console.error('error while adding user', error);
            toast.error('Something Went Wrong!');
            setLoading(false);
          })
      }
    } else {
      toast.error('Need to Login again!');
    }
  }

  const onChangeFile = (e) => {
    console.log('getting event on input img', e.target.files[0]);
    setImageToCrop(URL.createObjectURL(e.target.files[0]));
    // setImageChanged(true);
    setIsCroping(true);
  }

  const urltoFile = (url, filename, mimeType) => {
    return (fetch(url)
      .then(function (res) { return res.arrayBuffer(); })
      .then(function (buf) { return new File([buf], filename, { type: mimeType }); })
    );
  }

  const resizeFile = (file) =>
    new Promise((resolve) => {
      Resizer.imageFileResizer(
        file,
        1800,
        600,
        "webp",
        100,
        0,
        (uri) => {
          resolve(uri);
        },
        "file"
      );
    });

  const crop = async () => {
    const fileData = cropper?.current?.cropper?.getCroppedCanvas().toDataURL();
    setIsCroping(false);
    setFile(fileData);
  }

  const onProgress = (p) => {
    setProgress(p);
  }

  return (
    <React.Fragment>
      <div className="">
        <Row>
          <Col>
            <Card>
              <CardBody>
                <div className="">
                  <div className="d-flex" style={{ marginLeft: 'auto' }}>
                    <div>
                      <CardTitle className="h4">{data ? 'Update' : 'Add New'} Notification</CardTitle>
                      <p className="card-title-desc">
                        This <code>Notification</code> Will be just saved not send, For sending to target you need to click on <code>send button.</code>
                      </p>
                    </div>
                    <div className="text-center" style={{ marginLeft: 'auto' }}>
                      <Button color="primary" onClick={() => { props.setCurrentNoti(null); props?.close(false) }} style={{ backgroundColor: Utils.themeColor, border: 'none' }}>
                        Close
                      </Button>
                    </div>
                  </div>
                  {/* Image cropper modal */}
                  <Modal className="modal_form" isOpen={isCroping} toggle={() => { setIsCroping(false); setFile(null) }} centered={true}>
                    <ModalBody className="py-3 px-5">
                      <Cropper
                        style={{ height: 'auto', width: 600 }}
                        aspectRatio={512 / 256}
                        preview=".img-preview"
                        guides={true}
                        src={imageToCrop}
                        ref={cropper}
                      />
                      <Row className='mt-4'>
                        <Col md={6}>
                          <button
                            className="btn btn-primary w-100 waves-effect waves-light"
                            style={{ backgroundColor: Utils.themeColor }}
                            onClick={() => { setIsCroping(false); setFile(null) }}
                            type="reset"
                          >
                            Cancel
                          </button>
                        </Col>
                        <Col md={6}>
                          <button
                            className="btn btn-primary w-100 waves-effect waves-light"
                            onClick={crop}
                            style={{ backgroundColor: Utils.themeColor }}
                          >
                            Crop
                          </button>
                        </Col>
                      </Row>
                    </ModalBody>
                  </Modal>
                  <ProgressBar
                    visible={progress < 1 ? false : true}
                    progress={progress}
                  />
                  <AvForm
                    className="form-horizontal"
                    onValidSubmit={(e, v) => {
                      handleValidSubmit(e, v)
                    }}
                  >
                    <Row>
                      <Col lg={6} md={6}>
                        {file ?
                          <div className="img-banner">
                            <img src={file} />
                            <div className="cross-btn" onClick={() => setFile(null)}>
                              <i className="mdi mdi-close" />
                            </div>
                          </div>
                          :
                          <div className="form-group mb-4">
                            <AvField
                              name="image"
                              label={"Notification Image"}
                              className="form-control"
                              placeholder={`Upload Notification Image`}
                              type="file"
                              onChange={onChangeFile}
                            />
                          </div>
                        }
                      </Col>
                      <Col lg={6} md={6}>
                        <div className="form-group mb-4">
                          <AvField
                            name="target"
                            label={"Notification Target"}
                            value={target}
                            className="form-control"
                            placeholder={`Select Notification Target`}
                            type="select"
                            required
                            onChange={e => setTarget(e.target.value)}
                          >
                            <option value="">Select Target</option>
                            <option value="All">All</option>
                            <option value="Unregistered">Unregistered</option>
                            <option value="Registered">Registered</option>
                            <option value="Paid">Paid</option>
                          </AvField>
                        </div>
                      </Col>
                      <Col lg={6} md={6}>
                        <div className="form-group mb-4">
                          <AvField
                            name="type"
                            label={"Notification Type"}
                            value={type}
                            className="form-control"
                            placeholder={`Select Notification Type`}
                            type="select"
                            required
                            onChange={e => setType(e.target.value)}
                          >
                            <option value="">Select Type</option>
                            <option value="Instant">Instant</option>
                            <option value="Schedule">Schedule</option>
                          </AvField>
                        </div>
                      </Col>
                      {type == 'Schedule' &&
                        <>
                          <Col md={6}>
                            <div className="form-group mb-4">
                              <AvField
                                name="date"
                                label={"Date"}
                                value={data?.triggerOn ? moment(data?.triggerOn).format("YYYY-MM-DD HH:MM") : undefined}
                                className="form-control"
                                type="datetime-local"
                                // onChange={handleInputChange}
                                required
                              >
                              </AvField>
                            </div>
                          </Col>
                        </>
                      }
                      <Col lg={6} md={6}>
                        <div className="form-group mb-4">
                          <AvField
                            name="isNavigate"
                            label={"Navigation"}
                            value={isNavigate}
                            className="form-control"
                            type="select"
                            required
                            onChange={e => setIsNavigate(e.target.value)}
                          >
                            <option value="">Select Navigation</option>
                            <option value="Yes">Yes</option>
                            <option value="No">No</option>
                          </AvField>
                        </div>
                      </Col>
                      {isNavigate == 'Yes' &&
                        <Col lg={6} md={6}>
                          <div className="form-group mb-4">
                            <AvField
                              name="navigationType"
                              label={"Select Navigation Type"}
                              value={data?.navigationType}
                              className="form-control"
                              type="select"
                              onChange={(e) => setNavigationType(e.target.value)}
                              required
                            >
                              <option value="">Select Navigation Type</option>
                              <option value="internalPage">Internal App Screen</option>
                              <option value="inApp">In App Link</option>
                              <option value="outApp">External Web Link</option>
                            </AvField>
                          </div>
                        </Col>
                      }
                      {isNavigate == 'Yes' &&
                        <>
                          {navigationType == 'internalPage' ?
                            <>
                              <Col lg={6} md={6}>
                                <div className="form-group mb-4">
                                  <AvField
                                    name="screenName"
                                    label={"Select App Screen"}
                                    value={data?.screenName}
                                    className="form-control"
                                    type="select"
                                    required
                                    onChange={(e) => setSelectedScreen(e.target.value)}
                                  >
                                    <option value="">Select App Screen</option>
                                    {screens?.map((item) => (
                                      <option value={item?.for}>{item?.title}</option>
                                    ))}
                                  </AvField>
                                </div>
                              </Col>
                              {selectedScreen == "kahaniDetail" &&
                                <Col lg={6} md={6}>
                                  <div className="form-group mb-4">
                                    <AvField
                                      name="link"
                                      label={`Select Story`}
                                      value={data?.link}
                                      className="form-control"
                                      type="select"
                                      required
                                    >
                                      <option value="">Select Story</option>
                                      {dropData?.map((item) => (
                                        <option value={item?._id}>{item?.titleEn}</option>
                                      ))}
                                    </AvField>
                                  </div>
                                </Col>
                              }
                              {selectedScreen == "VratDetail" &&
                                <Col lg={6} md={6}>
                                  <div className="form-group mb-4">
                                    <AvField
                                      name="link"
                                      label={`Select Event`}
                                      value={data?.link}
                                      className="form-control"
                                      type="select"
                                      required
                                    >
                                      <option value="">Select Event</option>
                                      {dropData?.map((item) => (
                                        <option value={item?._id}>{item?.title} ({moment(item?.date).format("DD-MMM-YYYY")})</option>
                                      ))}
                                    </AvField>
                                  </div>
                                </Col>
                              }
                            </>
                            :
                            <Col lg={12} md={12}>
                              <div className="form-group mb-4">
                                <AvField
                                  name="link"
                                  label={"Web URL"}
                                  value={data?.link}
                                  className="form-control"
                                  placeholder={`Enter Url you need to open`}
                                  type="url"
                                  required
                                />
                              </div>
                            </Col>
                          }
                        </>
                      }
                      <Col lg={6} md={6}>
                        <div className="form-group mb-4">
                          <AvField
                            name="title"
                            label={"Notification Title"}
                            value={data?.title}
                            className="form-control"
                            placeholder={`Enter Title`}
                            type="text"
                            required
                          />
                        </div>
                      </Col>
                      <div className="form-group mb-4">
                        <AvField
                          name="body"
                          label={"Notification Description"}
                          value={data?.body}
                          className="form-control"
                          placeholder={`Enter Description`}
                          type="text"
                          required
                          validate={{ maxLength: { value: 200 } }}
                          onChange={(t) => setDescLength(t.target.value.length)}
                        />
                      </div>
                      <div className="mt-3" style={{ textAlign: 'end' }}>
                        <p>{descLength}/200</p>
                      </div>
                    </Row>

                    {/* <AvField name="idx" value={user?.company._id} type="hidden" /> */}
                    <div className="text-center mt-4">
                      {data?._id ?
                        <Button type="submit" color="primary" style={{ backgroundColor: Utils.themeColor, border: 'none' }}>
                          Update Notification
                        </Button>
                        :
                        <Button type="submit" color="primary" style={{ backgroundColor: Utils.themeColor, border: 'none' }}>
                          Add Notification
                        </Button>
                      }
                    </div>
                  </AvForm>
                </div>
              </CardBody>
            </Card>
          </Col>
        </Row>
      </div>
    </React.Fragment>
  )
}

export default AddUserForm;
